import Portfolio from '../models/Portfolio'
import apiWrapper from './ApiWrapper'

export function getUserAllPortfolios() {
    return new Promise((resolve, reject) => {
        apiWrapper.post('/api/Portfolio/GetUserPortfoliosInfo').then(portfolioInfos => {
            const portfolios = portfolioInfos.portfolios.map(p => new Portfolio({ ...p }))
            // TODO : portfolioInfos.transactions
            resolve(portfolios)
        }).catch(error => {
            reject(error)
        })
    })
}

export function addPortfolio({ portfolioName, portfolioDescription, currency }) {
    return new Promise((resolve, reject) => {
        apiWrapper.post('/api/Portfolio/AddPortfolio', { portfolioName, portfolioDescription, currency }, true).then((response) => {
            const convertedResponse = {
                portfolio: response.data,
                usage: response.additionalData.updatedUsage
            };
            resolve(convertedResponse)
        }).catch(error => {
            reject(error)
        })
    })
}

export function updatePortfolio({ portfolioId, portfolioName, portfolioDescription, currency }) {
    return apiWrapper.post(`/api/Portfolio/UpdatePortfolio`, { portfolioId, portfolioName, portfolioDescription, currency })
}

export function deletePortfolio({ portfolioId }) {
    return apiWrapper.post('/api/Portfolio/DeletePortfolio', { portfolioId })
}

export function addPortfolioStock({ portfolioId, stockId }) {
    return new Promise((resolve, reject) => {
        apiWrapper.post('/api/Portfolio/AddPortfolioStock', { portfolioId, stockId }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export function addPortfolioStockLot({ portfolioId, stockId, transactionType, transactionDate, stockQty, averageCostByShare, note }) {
    const action = `/api/Portfolio/AddPortfolioStock${transactionType}Lot`
    return new Promise((resolve, reject) => {
        apiWrapper.post(action, { portfolioId, stockId, transactionDate, stockQty, averageCostByShare, note }, true).then((response) => {
            const p = response.additionalData.updatedPortfolio
            const portfolio = new Portfolio({ ...p })
            resolve(portfolio)
        }).catch(error => {
            reject(error)
        })
    })
}

export function updatePortfolioStockLot({ portfolioId, stockId, lotId, transactionType, transactionDate, stockQty, averageCostByShare, note }) {
    return new Promise((resolve, reject) => {
        apiWrapper.post('/api/Portfolio/UpdatePortfolioStockLot', { portfolioId, stockId, lotId, transactionType, transactionDate, stockQty, averageCostByShare, note }, true).then(response => {
            const p = response.additionalData.updatedPortfolio
            const portfolio = new Portfolio({ ...p })
            resolve(portfolio)
        }).catch(error => {
            reject(error)
        })
    })
}

export function deletePortfolioStockLot({ portfolioId, stockId, lotId }) {
    return new Promise((resolve, reject) => {
        apiWrapper.post('/api/Portfolio/DeletePortfolioStockLot', { portfolioId, stockId, lotId }, true).then((response) => {
            const p = response.additionalData.updatedPortfolio
            const portfolio = new Portfolio({ ...p })
            resolve(portfolio)
        }).catch(error => {
            reject(error)
        })
    })
}

export function importInPortfolio({ portfolioId, fileInput }) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('portfolioInfo', JSON.stringify({ portfolioId }));
        formData.append('csvFile', fileInput);
        apiWrapper.post('/api/Portfolio/ImportPortfolio', formData, true).then(response => {
            const importPortfolioResult = response.additionalData.importPortfolioResult;
            console.log(importPortfolioResult);
            const portfolio = new Portfolio({ ...importPortfolioResult.portfolio });
            resolve({
                portfolio,
                exceptions: importPortfolioResult.exceptions
            })
        }).catch(error => {
            reject(error)
        })
    })
}

export function movePortfolioStock({ fromPortfolioId, toPortfolioId, stockId }) {
    return new Promise((resolve, reject) => {
        apiWrapper.post('/api/Portfolio/MovePortfolioStock', { fromPortfolioId, toPortfolioId, stockId }, true).then(response => {
            const p = response.additionalData
            const updatedPortfolios = {
                fromPortfolio: new Portfolio({ ...p.fromPortfolio }),
                toPortfolio: new Portfolio({ ...p.toPortfolio })
            }
            resolve(updatedPortfolios)
        }).catch(error => {
            reject(error)
        })
    })
}